import {Injectable} from '@angular/core';
import {ObservableSet} from "@juulsgaard/rxjs-tools";
import {map} from "rxjs/operators";
import {auditTime, combineLatest, Observable, startWith} from "rxjs";

@Injectable()
export class AsideService {

  private readonly sidebars$ = new ObservableSet<object>();

  constructor() {
  }

  registerSidebar(directive: object): SidebarStatus {
    this.sidebars$.toggle(directive, true);
    return new SidebarStatus(this.sidebars$, directive);
  }

  unregisterSidebar(directive: object) {
    this.sidebars$.toggle(directive, false);
  }
}

class SidebarStatus {

  constructor(sidebars$: ObservableSet<object>, obj: object) {
    this.position$ = sidebars$.array$.pipe(map(x => x.findIndex(x => x === obj)));
    this.total$ = sidebars$.size$;
    this.depth$ = combineLatest([this.position$, this.total$]).pipe(
      auditTime(0),
      map(([pos, total]) => Math.max(0, total - pos - 1)),
      startWith(0),
    );
    this.breakpoint$ = this.depth$.pipe(map(depth => 1350 + 400 * depth));
  }

  position$: Observable<number>;
  total$: Observable<number>;
  depth$: Observable<number>;
  breakpoint$: Observable<number>;
}
